<template>
  <div
    class="virtual-tour__point">
    <a href="javascript:void(0);"
      class="ra-gtm-event"
      :data-gtm-section="parent"
      :data-gtm-subsection="subSection"
      :data-gtm-label="gtmLabel"
      :ref="ref">
      <slot></slot>
      <span :class="position">
        {{ title }}
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Hotspot',
  props: {
    ref: Object,
    gtmLabel: String,
    parent: String,
    position: {
      type: String,
      default: 'top',
    },
    subSection: String,
    title: String,
  },
};
</script>

<style lang="scss">
  @import '@/scss/components/ui/virtualTourPoint.scss';
</style>
