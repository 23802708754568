<template>
  <a
    :href="href"
    class="cta ra-gtm-event">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    href: String,
  },
};
</script>

<style lang="scss">
  @import '@/scss/components/ui/button.scss';
</style>
