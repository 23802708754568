<template>
  <div :class="['tij-comparisson', {'tij-comparisson--large': $i18n.locale === 'fr'}]">
    <div class="single-inkjet single-inkjet--thermal" ref="leftCol">
      <div class="single-inkjet__img">
        <div>
          <span>
            {{ $t('technology.HP-TIJ.comparisson.printhead-1') }}
          </span>
          <img src="images/technology/thermal-inkhead.png" alt="">
        </div>
        <div>
          <span>
            {{ $t('technology.HP-TIJ.comparisson.printhead-2') }}
          </span>
          <img src="images/technology/thermal-inkhead.png" alt="">
        </div>
      </div>
      <div class="single-inkjet__content">
        <h4 class="title title--medium title--blue">
          {{ leftCol.title }}
        </h4>
        <div
          class="content"
          v-for="(item, index) in leftCol.content"
          :key="index"
          v-html="item.item_list"></div>
      </div>
    </div>
    <div class="comparisson__vs" ref="vs">
      <div class="line line--top"></div>
      <span>
        {{ $t('technology.HP-TIJ.comparisson.vs') }}
      </span>
      <div class="line line--bottom"></div>
    </div>
    <div class="single-inkjet single-inkjet--piezo" ref="rightCol">
      <div class="single-inkjet__img">
        <div>
          <span>
            {{ $t('technology.HP-TIJ.comparisson.printhead-right') }}
          </span>
          <img src="images/technology/piezo-inkhead.png" alt="">
        </div>
      </div>
      <div class="single-inkjet__content">
        <h4 class="title title--medium title--blue">
          {{ rightCol.title }}
        </h4>
        <div
          class="content"
          v-for="(item, index) in rightCol.content"
          :key="index"
          v-html="item.item_list"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import { gsap } from 'gsap';

import { showElement } from '@/common/animations';

export default {
  name: 'Comparisson',
  props: {
    data: Object,
  },
  mounted() {
    gsap.timeline()
      .add(showElement(this.$refs.leftCol))
      .add(showElement(this.$refs.vs))
      .add(showElement(this.$refs.rightCol));
  },
  computed: {
    leftCol() {
      return this.data.content[0];
    },
    rightCol() {
      return this.data.content[1];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/tij/comparisson.scss';
</style>
