<template>
  <div class="member-card">
    <!-- eslint-disable max-len  -->
    <div class="d-flex w-100 flex-row align-items-stretch justify-content-center mb-2">
      <div class="d-flex number-col align-items-center justify-content-center">
        <span class="h2 m-0">
          {{ idx }}
        </span>
      </div>
      <div class="form-col">
        <!-- Close button -->
        <button
          class="hamburger--collapse is-active close js-close-new-member"
          type="button"
          @click="stopCreating">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <!--  // Close button -->

        <!-- Search member inside database -->
        <div class="wrap-search-member js-search-member">
          <div class="search-member__input">
            <input
              autocomplete="off"
              class="form-control"
              id="search-member"
              name="search-member"
              placeholder="Search Team Member"
              ref="searchInput"
              required
              type="text"
              v-model="keyword"
              @focus="showDropdown"
              @keyup="showDropdown"
              @blur="hideDropdown">

            <div class="input-drop-down js-member-dropdown" style="display:none;">
              <ul class="my-2 p-2">
                <li>
                  <a class="blue" href="javascript:void(0)" @click="createNewMember">
                    {{ $t('profile.add-member') }}
                  </a>
                </li>

                <li v-for="(member, index) in memberList" :key="index" @click="addToTeam(member.id)">
                  <a href="javascript:void(0)">
                    {{ member.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Create new member form -->
        <div class="wrap-create-member js-create-member">
          <div class="row">
            <div class="col-md-5">
              <div class="create-member__img">
                <div class="wrap-pic">
                  <img :src="uploadedImage" class="pic" alt="">
                </div>
                <i
                  aria-hidden="true"
                  class="fa fa-camera"
                  @click="toggleImgOptions"></i>

                <div class="profile-img-edit" ref="imgOptions">
                  <input
                    class="imageFile"
                    name="file"
                    type="file"
                    @change="onFileChange">
                  <button
                    class="cta"
                    type="button"
                    @click="uploadImage">
                    {{ $t('buttons.upload') }}
                  </button>
                  <small>
                    {{ $t('common.click-icon') }}
                  </small>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <form id="create-member">
                <div class="form-group text-left">
                  <label for="firstName">
                    {{ $t('profile.form.first-name') }}*
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('profile.form.first-name')"
                    required
                    v-model="localData.firstname">
                </div>
                <div class="form-group text-left">
                  <label for="lastName">
                    {{ $t('profile.form.last-name') }}*
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('profile.form.last-name')"
                    required
                    v-model="localData.lastname">
                </div>
                <div class="form-group text-left">
                  <label for="title">
                    {{ $t('profile.form.title') }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('profile.form.title')"
                    v-model="localData.title">
                </div>

                <button
                  class="cta cta--border small ra-gtm-event"
                  data-gtm-section="My Profile"
                  data-gtm-label="Add Member"
                  href="javascript:void(0)"
                  type="submit"
                  @click="submitForm">
                  {{ $t('profile.form.add-member') }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import { mapState } from 'vuex';

import * as endpoints from '@/common/endpoints';
import { filters, loginMixins } from '@/common/mixins';

export default {
  name: 'Create Team Member',
  mixins: [filters, loginMixins],
  props: {
    data: Object,
    idx: Number,
  },
  emits: ['close-create'],
  data() {
    return {
      keyword: '',
      memberList: [],
      tmpImage: null,
      isLoading: false,
      uploadedImage: 'images/headshot.jpg',
    };
  },
  methods: {
    showDropdown() {
      axios.post(
        `${endpoints.BASEAPIURL}searchuser${endpoints.EXT}`,
        { term: this.keyword, teamstring: '' },
      )
        .then((response) => {
          // console.log(response);
          const json = response.data;
          if (json.reply === 1) {
            $('.js-member-dropdown').slideDown();
            if (json.response !== 'No matches') {
              this.memberList = json.response;
            }
          } else {
            console.error(json.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hideDropdown() {
      $('.js-member-dropdown').slideUp();
    },
    addToTeam(id) {
      axios.post(
        `${endpoints.BASEAPIURL}teamfunctions${endpoints.EXT}`,
        {
          action: 'retrieve',
          id,
        },
      )
        .then((response) => {
          // console.log(response);
          const json = response.data;
          if (json.reply === 1) {
            $('.js-member-dropdown').slideDown();
            if (json.response !== 'No matches') {
              // Reset Dorpdown Status
              this.keyword = '';
              this.hideDropdown();

              // close add member card and print member
              this.$parent.isCreatingMember = false;
              this.$store.commit('addMemberToTeam', json);

              // Enable save button
              this.$parent.teamHasChanges = true;
            }
          } else {
            console.error(json.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createNewMember() {
      $('.js-search-member').hide();
      $('.js-create-member').slideDown();

      this.parsleyTheForm('#create-member');
    },
    stopCreating() {
      this.keyword = '';
      this.hideDropdown();

      this.$emit('close-create');
      $('.js-search-member').show();
      $('.js-create-member').hide();
    },
    submitForm() {
      $('#create-member').parsley().on('form:submit', () => {
        console.log('submit and create', this.localData);

        axios.post(
          `${endpoints.BASEAPIURL}teamfunctions${endpoints.EXT}`,
          {
            action: 'insert',
            targetuser: this.salesPerson.email,
            region: this.salesPerson.region,
            photo: this.uploadedImage,
            ...this.data,
          },
        )
          .then((response) => {
            const json = response.data;
            console.log(response);
            if (json.reply === 1) {
              this.isLoading = false;
              this.keyword = '';

              // Clear Parent Data (Profile.vue)
              this.$parent.isCreatingMember = false;
            } else {
              console.error('else then', json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        return false;
      });
    },
    // Image file management
    toggleImgOptions() {
      // this.isUpdatingImage = !this.isUpdatingImage;
      $(this.$refs.imgOptions).slideToggle();
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      [this.tmpImage] = files;
    },
    uploadImage() {
      const formData = new FormData();
      formData.append('file', this.tmpImage);
      formData.append('action', 'newmember');
      axios.post(
        `${endpoints.BASEAPIURL}uploadfile${endpoints.EXT}`,
        formData,
      )
        .then((response) => {
          // console.log(response);
          const json = response.data;
          if (json.reply === 1) {
            this.uploadedImage = json.location;
            $(this.$refs.imgOptions).slideUp();
          } else {
            console.error(json.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapState([
      'loggedin',
      'salesPerson',
    ]),
    localData() {
      return this.data;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/profile/create-member.scss';
</style>
