import { createI18n } from 'vue-i18n';
import getAllParameters from '@/common/functions';
import basil from '@/common/cookies';

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const allparams = getAllParameters();

if (allparams.lang) {
  basil.set('locale', allparams.lang);
} else if (basil.get('locale')) {
  basil.get('locale');
} else {
  basil.set('locale', 'en');
}

const cookiedLocale = basil.get('locale');

const i18n = createI18n({
  locale: cookiedLocale,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
});

export default i18n;
