import { createStore } from 'vuex';

import basil from '@/common/cookies';

// Create a new store instance.
export default createStore({
  state: {
    currentPrinter: 3,
    sharedList: [],
    loggedin: 0,
    salesPerson: {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      photo: '',
      region: '',
      superadmin: '',
      team: [],
      title: '',
      language: '',
    },
    companyName: '',
    companyContact: {
      email: '',
      firstname: '',
      lastname: '',
      title: '',
      phone: '',
      team: [],
    },
  },
  mutations: {
    fillSalesPersonData(state, payload) {
      state.salesPerson = payload;
    },
    fillCompanyName(state, payload) {
      state.companyName = payload;
    },
    fillCompanyData(state, payload) {
      state.companyContact = payload;
    },
    addToCompanyTeam(state, payload) {
      state.companyContact.team = payload;
    },
    login(state, payload) {
      state.loggedin = payload;
    },
    changePrinter(state, payload) {
      state.currentPrinter = payload;
      basil.set('currentPrinter', payload);
    },
    addToShare(state, payload) {
      state.sharedList = [
        ...state.sharedList,
        payload,
      ];
    },
    removeItem(state, payload) {
      state.sharedList = state.sharedList.filter((item) => item.hash !== payload.hash);
    },
    addMemberToTeam(state, payload) {
      state.salesPerson.team = [
        ...state.salesPerson.team,
        payload,
      ];
    },
    removeMemberFromTeam(state, payload) {
      state.salesPerson.team = state.salesPerson.team.filter((member) => member.id !== payload);
    },
  },
});
