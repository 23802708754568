<template>
  <Media
    data-gtm-section="Technology"
    data-gtm-subsection="HP TIJ"
    data-gtm-label="Watch TIJ Video"
    poster="images/technology/video-tij-poster.jpg"
    :src="data.video"
    :title="data.caption_video" />
</template>

<script>
import Media from '@/components/ui/Media.vue';

export default {
  name: 'TIJVideo',
  props: {
    data: Object,
  },
  components: {
    Media,
  },
};
</script>
