<template>
  <div class="about-hp">
    <!-- eslint-disable max-len -->
    <div class="logo">
      <svg viewBox="0 0 432 432" fill="#ffffff">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M216,432c-2.15,0-4.29-0.04-6.42-0.1l44.14-121.32h60.75c10.66,0,22.37-8.2,26.01-18.21l47.89-131.55 c7.84-21.54-4.5-39.16-27.42-39.16h-84.28l-70.71,194.32h-0.03l-40.09,110.15C70.74,403.52,0,318.03,0,216 C0,116.92,66.72,33.42,157.68,7.97l-41.37,113.69h-0.01L47.54,310.58l45.78, 0l58.48-160.53h34.4l-58.48,160.53l45.76,0.01 l54.5-149.77c7.84-21.54-4.5-39.16-27.39-39.16h-38.48L206.29,0.23C209.51,0.08,212.75,0,216,0c119.29,0,216,96.71,216,216 C432,335.29,335.29,432,216,432z M346.69,149.94H312.3L264.13,282.1h34.4L346.69,149.94z" />
      </svg>
    </div>
    <div v-html="data.about_hp_content"></div>
  </div>
</template>

<script>
export default {
  name: 'AboutHP',
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/about-hp.scss';
</style>
