<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-xl-7">
      <div class="heritage-intro">
        <p>
          {{ data.heritage_intro_label }}
        </p>
        <h3 class="title title--big">
          {{ data.heritage_intro_headline }}
        </h3>
      </div>
      <div class="heritage-content">
        <div class="capital">
          <p>
            {{ data.start_capital_label }}
          </p>
          <strong>
            {{ data.start_capital_content }}
          </strong>
        </div>
        <div class="customer">
          <div>
            <p>
              {{ data.earliest_customer_label }}
            </p>
            <strong>
              {{ data.earliest_customer_content }}
            </strong>
          </div>
          <img src="images/about-us/disney-logo.svg" alt="" class="logo">
        </div>
        <div class="product">
          <div>
            <p>
              {{ data.first_product_label }}
            </p>
            <strong>
              {{ data.first_product_content }}
            </strong>
          </div>
          <img src="images/about-us/hp200a-front-panel.jpg" alt="" class="product__img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Heritage',
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/hp-heritage.scss';
</style>
