import { gsap } from 'gsap';

const showElement = (element, origin = '', additional = {}) => {
  const originObject = {};

  switch (origin) {
    case 'top':
      originObject.y = '-=15px';
      break;

    case 'left':
      originObject.x = '-=15px';
      break;

    case 'right':
      originObject.x = '+=15px';
      break;

    case 'bottom':
      originObject.y = '+=15px';
      break;

    default:
      break;
  }

  return gsap.timeline()
    .from(element, {
      ...originObject,
      duration: 0.2,
      opacity: 0,
      ease: 'power2.in',
      stagger: 0.2,
      ...additional,
    });
};

const bounceIn = (el) => gsap.timeline()
  .to(el, {
    opacity: 1,
    visibility: 'visible',
    duration: 0.3,
    ease: 'power4.out',
  })
  .to(el, {
    translateY: 0,
    duration: 0.6,
    ease: 'bounce.out',
  }, '-=0.2');

const bounceOut = (el) => gsap.timeline()
  .to(el, {
    translateY: -20,
    duration: 0.8,
    ease: 'power3.out',
  })
  .to(el, {
    opacity: 0,
    visibility: 'hidden',
    duration: 0.3,
    ease: 'power4.out',
  }, '-=0.5');

const moveForklift = (detail, forklift, posLeft) => gsap.timeline()
  .set(detail, {
    x: posLeft,
    translateY: 0,
  })
  .to(detail, {
    opacity: 1,
    visibility: 'visible',
    duration: 0.3,
    ease: 'power4.out',
  })
  .to(detail, {
    x: 0,
    duration: 3,
    ease: 'linear',
  }, '-=0.2')
  .to(forklift, {
    x: '100vw',
    duration: 3,
    ease: 'power1.out',
  }, '+=0.2');

export {
  showElement,
  bounceIn,
  bounceOut,
  moveForklift,
};
