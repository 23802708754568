<template>
  <div class="member-card">
    <div class="d-flex w-100 flex-row align-items-stretch justify-content-center mb-3">
      <div class="d-flex number-col align-items-center justify-content-center">
        <span class="h2 m-0">
          {{ idx }}
        </span>
      </div>
      <div class="form-col px-3 py-2">
        <div class="row align-items-center">
          <div class="col-md-4">
            <div class="member-card__img">
              <img
                :alt="`${data.firstname} ${data.lastname}`"
                :src="data.photo || 'images/headshot.jpg'">
            </div>
          </div>
          <div class="col-md-8">
            <div class="member-card__info">
              <h4>
                {{ data.firstname }} {{ data.lastname }}
              </h4>
              <p>
                {{ data.title }}
              </p>
              <a
                href="javascript:void(0);"
                @click="$emit('delete-member', data.id)">
                <i class="fa fa-trash-o"></i>
              </a>
            </div>
          </div>
          <div class="member-card__sort">
            <i class="fa fa-caret-up"></i>
            <i class="fa fa-bars"></i>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

import * as endpoints from '../../common/endpoints';
import { filters } from '../../common/mixins';

export default {
  name: 'Team Member Card',
  mixins: [filters],
  emits: ['delete-member'],
  props: {
    data: Object,
    idx: Number,
  },
  methods: {
    /**
     * @param {number} id The id of the user to be deleted
     */
    deleteContact(id) {
      axios.post(
        `${endpoints.BASEAPIURL}presentationfunctions${endpoints.EXT}`,
        {
          action: 'delete',
          id,
          login: this.loggedin,
        },
      )
        .then(() => {
          console.log(`Deleted contact id: ${id}`);

          this.$emit('delete-contact', id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapState([
      'salesPerson',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/profile/member-card.scss';
</style>
